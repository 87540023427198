import React from 'react';
import Typography from '@mui/material/Typography';
import BlocTextFiled from '../utils/BlocTextFiled';
import ButtonSendPack from '../utils/buttonSendPack';
import PriceInput from '../utils/priceInput';
import FullWidthTextField from '../utils/LargeTextField';
import Alert from '@mui/material/Alert';

import { baseUrl } from '../../environment/env.ts';

export default class Pack extends React.Component {
    constructor(props) {
       super(props);
       this.addPack = this.addPack.bind(this);
       this.state = {alertMsg : ''};
    
    }


    async addPack(){
      const formData = new FormData();
      // Pack 
      let budget = document.getElementById('filled-adornment-amount').value;
      budget = (budget === '') ? (0.0) : parseFloat(budget);
      const expectations = document.getElementById('expectations').value;
      const debits = document.getElementById('debits').value;
      const packTitle = document.getElementById('fullWidth').value;

      const packObject = {
         budget : budget,
         expectations : expectations,
         debits : debits,
         title : packTitle
      };
      const body = JSON.stringify(packObject);

      formData.append('pack', body);

      //multipart image
      formData.append('image', document.getElementById('imageInput').files[0]);

      const requestOptions = {
        ...this.props.authorization,
         method : 'POST',
         body : formData
      };
      const response = await fetch(`${baseUrl}/pack`,requestOptions);

      const successAlert = <Alert severity="success">Pack successfully created.</Alert>;
      const failedAlert = <Alert severity="error">An error occured.</Alert>;
      const alertMsg = (response.ok) ? successAlert : failedAlert;
      this.setState({alertMsg : alertMsg});

      console.log(await response.json());
   }

    render() {
        return (
            <div>
              <div>
                {this.state.alertMsg}
              </div>
              <Typography  variant="h5" component="div" >
                Choix du budget : 
                <PriceInput/>
              </Typography>

              <Typography gutterBottom variant="h5" component="div">
                  Initialisation des Prélèvements : 
                  <BlocTextFiled id="debits" label="prélèvements"/>
              </Typography>
              
              <Typography gutterBottom variant="h5" component="div">
                  Initialisation des Prévisions : 
                  <BlocTextFiled id="expectations" label="prévisions"/>
              </Typography>

              <Typography gutterBottom variant="h5" component="div">
                  Titre de la Formule : 
                  <FullWidthTextField/>
                  <input type="file" name="image" id="imageInput"/>
              </Typography>
              

              <div>
              <ButtonSendPack onClick={this.addPack}/>
              </div>
              
            </div>
        );
     }
}