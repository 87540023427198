import {User} from '../model/user.model';
import { baseUrl } from '../environment/env.ts';

export const addUser = async (user : User) : Promise<Response> => {
    const body = JSON.stringify(user);
    const requestOptions = {
        method :'POST',
        headers : { "Content-Type": "application/json" },
        body : body
    };
    const response = await fetch(`${baseUrl}/access/register`, requestOptions);
    return response;
};

export const verifyUser = async (user : User) : Promise<Response> => {
    const body = JSON.stringify(user);
    const requestOptions = {
        method :'POST',
        headers : { "Content-Type": "application/json" },
        body : body
      };
    const response = await fetch(`${baseUrl}/access/login`, requestOptions);
    return response;
};