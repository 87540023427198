import React from 'react';
import PackCard from './packCard';
import Typography from '@mui/material/Typography';
import { baseUrl } from '../../environment/env.ts';

export default class PackChoose extends React.Component {
    constructor(props) {
       super(props);
       this.state = {list:null};
       this.handleClick = this.handleClick.bind(this);
       this.deletePack = this.deletePack.bind(this);
       this.getListOfPack = this.getListOfPack.bind(this);
    }

  componentDidMount(){
    this.getListOfPack();
  }

  componentDidUpdate(prevProps){
    if (prevProps.packs !== this.props.packs) {
      this.getListOfPack();
    }
  }

  getListOfPack(){
    const packs = this.props.packs;
    this.setState({list : this.createListOfPacks(packs)});
  }

    createImg(imgData) {
      const byteCharacters = atob(imgData);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' }); // Remplacez par le type approprié
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
  }

    createListOfPacks(packObjects){
      return packObjects.map(pack => 
        <PackCard 
        id={pack.id} 
        key={pack.id} 
        title={pack.title}
        budget={pack.budget} 
        src={this.createImg(pack.image.imageData)}
        handleClick = {this.handleClick}
        deletePack = {this.deletePack}
        />
        );
    }

    async initializePack(id){
      const response = fetch(`${baseUrl}/pack/initialize/${id}`,{...this.props.authorization,method:'GET'});
    }

     handleClick(id){
      this.initializePack(id);
    }

    async deletePack(id){
      const response = await fetch(`${baseUrl}/pack/${id}`, {...this.props.authorization,method:'DELETE'});
      this.update();
    }

    update(){
      this.props.update('pack');
      this.getListOfPack();
   }

    render() {
        return (
          <div>
            <Typography  variant="h4" component="div" color="black">
                  Choix du pack pour ce mois : 
              </Typography>
              <div style={{padding:40, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {this.state.list}
              </div>
          
          </div>
        );
     }
}