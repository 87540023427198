const deleteIdSpaceInUrl = (id : String) : String => id.split('%').join('');

console.log(deleteIdSpaceInUrl("test%dsd"));




const getUserToken = () : string | null => localStorage.getItem('token');

export const getAuthorizedHeader = () => (
            { 
             headers : { 'Authorization': getUserToken()} 
            });