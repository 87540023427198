import Debit from './debit.jsx';
import Expense from './expense.jsx';
import Expectation from './expectation.jsx';
import Savings from './savings.jsx';
import HomeBar from '../utils/homebar.jsx';
import ButtonEnvironment from '../utils/buttonEnvironment.jsx';
import InventoryIcon from '@mui/icons-material/Inventory';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Link } from 'react-router-dom';
import InfoDebit from './info_debit.jsx';
import InfoExpectation from './info_expectation.jsx';
import { baseUrl } from '../../environment/env.ts';
import { getAuthorizedHeader } from '../../scripts/lib.ts';


export default class ExpenseApp extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
        list : null,
         info : null,
         user : {savings : 0},
         authorizedHeader : null,
         status : ''
        };
      this.handleClickEnv = this.handleClickEnv.bind(this);
      this.update = this.update.bind(this);
   }

componentDidMount() {
    this.setAuthorization(); 
}

setAuthorization() {
    this.setState({ authorizedHeader : getAuthorizedHeader()},
        () => { 
          this.initCallServices();
        }
    );
}

initCallServices(){
  this.getListOfDebits();
  this.getCurrentUser();
}

    async getListOfDebits(){
      const response = await fetch(`${baseUrl}/debits`,{...this.state.authorizedHeader,method: 'GET'});
      const debits = await response.json();
      this.setState({
        list : <Debit debits={debits} authorization={this.state.authorizedHeader} update={this.update}/>,
        info : <InfoDebit debits={debits}/>
      });
    }

    async getListOfexpectations(){
      const response = await fetch(`${baseUrl}/expectations`,{...this.state.authorizedHeader,method: 'GET'});
      const expectations = await response.json();
      this.setState({
        list : <Expectation expectations={expectations} authorization={this.state.authorizedHeader} update={this.update}/>,
        info : <InfoExpectation savings={this.state.user.savings} expectations={expectations}/>
      });
   }

   async getCurrentUser(){
    const response = await fetch(`${baseUrl}/user`,{...this.state.authorizedHeader,method: 'GET'});
    const user = await response.json();
    this.setState({user:user});
  }

   async getListOfExpenses(){
    let response = await fetch(`${baseUrl}/expenses`,{...this.state.authorizedHeader,method: 'GET'});
    const expenses = await response.json();
    response = await fetch(`${baseUrl}/expectations`,{...this.state.authorizedHeader,method: 'GET'});
    const expectations = await response.json();
    this.setState({
      list : <Expense expenses={expenses} authorization={this.state.authorizedHeader}
       expectations={expectations} update={this.update}/>,
      info : ""
  });
 }

   update(componentStr){
      this.matchingComponentAliases(componentStr);
      this.getCurrentUser();
   }

   handleClickEnv(event){
      this.matchingComponentAliases(event.target.id);
   }

   matchingComponentAliases(alias){
      if(alias === "debit")
          this.getListOfDebits();
      if(alias === "expense")
          this.getListOfExpenses();
      if(alias === "expectation")
          this.getListOfexpectations();
   }

   render() {
    return (
      <div className='app'>
        <HomeBar/>
        <div style={{ backgroundImage : 'url(/images/terrasse.jpg)' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginLeft: '70%' }}>
                <ButtonEnvironment onClick={this.handleClickEnv}/>
                </div>
                <div style={{ marginLeft: '300px' }}>
                <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: 'brown' }}
                        endIcon={<InventoryIcon />}
                        component={Link} to="/expenseApp/pack"
                      >
                        Pack
                      </Button>
                    </Stack>
                </div>

            </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Savings user={this.state.user} style={{ marginRight: '10px' }}/>
                <div style={{ marginLeft: '50px' }}>
                    {this.state.list}
                </div>
            </div>
        </div>
      </div>
            <div>
                  {this.state.info}
              </div>
      </div>
    );
  }
  
  
  
  
}
