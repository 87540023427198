import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default class InfoDebit extends React.Component {
    constructor(props) {
       super(props);
    }

    createLineInfo(totalOfDebit, sumOfDebited){
      const res = `Le total de tous tes prélèvements est de : ${totalOfDebit}€.
      Il y'a actuellement ${sumOfDebited}/${totalOfDebit} € prélevé (Soit il reste encore ${totalOfDebit-sumOfDebited} € à prélever).`;
      return res;
    }

    displayInfo(debitObjects){
      const totalOfDebit = debitObjects.reduce((prev,elm) => prev+elm.cost,0);
      const sumOfDebited = debitObjects.filter(elm => elm.isDebited).reduce((prev,elm) => prev+elm.cost,0);
      return this.createLineInfo(totalOfDebit,sumOfDebited);
    }

    render() {
        return (
            <Card sx={{  boxShadow: 15, width : '100%' }}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  Info : 
                </Typography>
                <Typography variant="body2" color="text.secondary" fontSize={25}>
                  {this.displayInfo(this.props.debits)}
                </Typography>
                
              </CardContent>

            </CardActionArea>
          </Card>  
        );
     }
}
